import { Link, navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import copy from "../../data/questionnaire.yml"
import useAuth, { AuthContext } from "../../hooks/useAuth"
import { Button } from "../Buttons/Button"
import { Check } from "../Icons/Check"
import { Close } from "../Icons/Close"

interface Question {
  questionID: string
  questionBody: string
  statusCode: string
  creationDate: string
  sortOrder: number
  answer1: string
  answer2: string
  answer3: string
}

interface VisitorQuestion {
  visitorID: string
  questionID: string
  questionBody: string
  response: string
  creationDate: string
}

const Questionnaire: React.FC = () => {
  const { isAuthenticated } = useAuth()
  const [open, setOpen] = useState(false)
  const { state, dispatch }: any = useContext(AuthContext)
  const [visitorID, setVisitorID] = useState<string | undefined>()
  const [header, setHeader] = useState<string>()
  const [questions, setQuestions] = useState<Question[]>([])
  const [question, setQuestion] = useState<Question>()
  const [responses, setResponses] = useState<Record<string, string>>({})
  const [response, setRepsonse] = useState<string>()
  const [validAnswer, setValidAnswer] = useState<boolean>(false)
  const [questionIndex, setQuestionIndex] = useState<number>(0)

  //Get visitorID
  useEffect(() => {
    fetch(`${process.env.GATSBY_API_ROOT_URL}/auth/whoami`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${state?.token}`,
      },
    })
      .then(res => res.json())
      .then(data => {
        setVisitorID(data.visitor.visitorID)
      })
      .catch(error => {
        navigate("/check-registration")
        console.error(error)
      })
  }, [])
  // Get questionnaire header to display
  useEffect(() => {
    getQuestionnaireHeader()
    async function getQuestionnaireHeader() {
      const url = `${process.env.GATSBY_API_ROOT_URL}/questionnaire`
      try {
        const res = await fetch(url, {
          method: "GET",
        })
        if (!res.ok) {
          throw new Error(res.statusText)
        }
        const payload: string = await res.json()
        setHeader(payload)
      } catch (e) {
        console.error("error fetching header", e.message)
      }
    }
  }, [])

  // Get questions to display
  useEffect(() => {
    getQuestions()
    async function getQuestions() {
      const url = `${process.env.GATSBY_API_ROOT_URL}/questions`
      try {
        const res = await fetch(url, {
          method: "GET",
        })
        if (!res.ok) {
          throw new Error(res.statusText)
        }
        const payload: Question[] = await res.json()
        setQuestions(
          payload.filter(question => question.statusCode === "Active")
        )
      } catch (e) {
        console.error("error fetching questions", e.message)
      }
    }
  }, [])

  // Get visitor's null responses to questions
  useEffect(() => {
    if (!state?.token || visitorID == undefined) {
      return
    }
    getVisitorQuestions()
    async function getVisitorQuestions() {
      const url = `${process.env.GATSBY_API_ROOT_URL}/visitors/${visitorID}/questions`
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            authorization: `Bearer ${state?.token}`,
          },
        })
        if (!res.ok) {
          throw new Error(res.statusText)
        }
        const payload: VisitorQuestion[] = await res.json()
        const questionIDToResponseMap: Record<string, string> = {}
        payload.forEach(question => {
          if (question.response == null)
            questionIDToResponseMap[question.questionID] = question.response
        })
        setResponses(questionIDToResponseMap)
      } catch (e) {
        console.error("error fetching responses", e.message)
      }
    }
  }, [state, visitorID])

  // Select unanswered question
  useEffect(() => {
    if (responses !== undefined && Object.keys(responses).length !== 0) {
      setOpen(true)
    }
    const unansweredQ = questions.filter(
      question => question.questionID == Object.keys(responses)[questionIndex]
    )[0]
    if (unansweredQ != undefined) {
      setQuestion(unansweredQ)
    } else {
      setOpen(false)
    }
  }, [questions, visitorID, responses, isAuthenticated, questionIndex])

  const onHandleSubmit = async (
    questionID: string,
    response: string,
    questionBody: string
  ) => {
    setValidAnswer(true)
    const url = `${process.env.GATSBY_API_ROOT_URL}/visitors/${visitorID}/questions/${questionID}`
    try {
      const res = await fetch(url, {
        method: "PUT",
        headers: {
          authorization: `Bearer ${state?.token}`,
          accept: "application/json",
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify({
          visitorID,
          questionID,
          response,
          questionBody,
        }),
      })
      if (!res.ok) {
        throw new Error(res.statusText)
      }
      const payload: VisitorQuestion = await res.json()
      setResponses(prevValue => ({
        ...prevValue,
        [payload.questionID]: payload.response,
      }))
    } catch (e) {
      console.error("error updating response", e.message)
    }
  }
  console.log(question)
  return (
    <div css={[tw`z-50 fixed right-5 bottom-5 `]}>
      <div css={[tw`relative`]}>
        <div
          css={
            open && state?.showQuestionnaire
              ? [
                  tw`bg-red-300 absolute rounded min-h-[17em] h-auto  w-[23em] md:w-[30em] bottom-0 right-[-.65em] md:right-0 shadow-md transition ease-in-out  duration-500`,
                ]
              : [tw`transition duration-500 ease-in-out h-0 w-0`]
          }
        >
          {open && (
            <div css={[tw`p-6 text-white text-center h-full min-h-[17em]`]}>
              <div css={[tw`flex flex-row justify-between content-end`]}>
                <p css={[tw`pb-3 text-sm text-beige`]}>{header.header}</p>

                <Close
                  css={[tw`text-white w-4 h-4`]}
                  onClick={() => {
                    setOpen(!open)
                    document.body.style.overflow = "scroll"
                  }}
                />
              </div>
              {!validAnswer && (
                <>
                  <p css={[tw`font-bold  text-left`]}>
                    {question?.questionBody}
                  </p>
                  <div css={[tw` my-3`]}>
                    {copy.responses.map((response: string, index: number) => (
                      <div key={response} css={[tw`flex flex-row w-full`]}>
                        <input
                          type="radio"
                          value={response}
                          name={question?.questionID}
                          onChange={() =>
                            setRepsonse(
                              index == 0
                                ? question.answer1
                                : index == 1
                                ? question.answer2
                                : question.answer3
                            )
                          }
                          css={[
                            tw`w-6 h-6 mb-3 mr-3 checked:bg-red-700 border border-2 border-beige bg-beige checked:bg-red-700 hover:scale-105`,
                            `accent-color: #e5554f;`,
                          ]}
                        />
                        {index == 0
                          ? question.answer1
                          : index == 1
                          ? question.answer2
                          : question.answer3}
                      </div>
                    ))}
                    <Button
                      dark
                      onClick={() =>
                        onHandleSubmit(
                          question?.questionID,
                          response,
                          question?.questionBody
                        )
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}

              {validAnswer && (
                <>
                  <p css={[tw`text-sm`]}>{copy.prompts[2]}</p>
                  <div css={[tw`grid grid-cols-2 gap-8 mt-1`]}>
                    <div
                      css={[
                        tw`col-span-1 text-white flex justify-end content-center items-center hover:underline hover:scale-105 cursor-pointer`,
                      ]}
                      onClick={() => {
                        setQuestionIndex(prev => prev + 1)
                        setValidAnswer(false)
                        setRepsonse("")
                      }}
                    >
                      <Check css={[tw`h-4 w-4 mr-2`]} />
                      <p>Yes</p>
                    </div>
                    <div
                      css={[
                        tw`col-span-1 text-white flex justify-start content-center items-center hover:underline hover:scale-105 cursor-pointer`,
                      ]}
                      onClick={() => {
                        setOpen(false)
                        document.body.style.overflow = "scroll"
                      }}
                    >
                      <Close css={[tw`h-3 w-3 mr-2`]} />
                      <p>No</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Questionnaire
